import { NgModule } from "@angular/core";
import { AppMaterialModule } from "src/app/shared/app.material";  
import { HeaderLayoutComponent } from "./header-layout.component";
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [HeaderLayoutComponent],
    imports: [
        AppMaterialModule, 
        RouterModule
    ],
    exports:[HeaderLayoutComponent]
})
export class HeaderLayoutModule { }