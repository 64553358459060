import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.less']
})
export class HeaderLayoutComponent implements OnInit {
  showRedirect = false;
  tokenError = false;
  activeUrl = '/';
  displayCartPreview = false; 

  visibleChange($event){
    this.displayCartPreview = $event.detail.visible;
  }

  constructor(private readonly router: Router, private readonly oauthService: OAuthService,) { }

  ngOnInit(): void {
    this.checkUrl();
    this.setOauthLoginHint();  
  }

  checkUrl() {
    this.activeUrl = this.router.url;

    if (this.router.url !== '/') {
      this.showRedirect = true;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects; 
        this.activeUrl = url;
        if (url === '/') {
          this.showRedirect = false;
        } else {
          this.showRedirect = true;
        }
      }
    })
  }

  checkChildUrl(urls){
    return urls.some(e=> e.url === this.activeUrl)
  }

  setOauthLoginHint(){
    const token= localStorage.getItem("access_token");
    if(token){
      const user = JSON.parse(atob(token.split(".")[1]));

      if(!this.oauthService.customQueryParams) {
        this.oauthService.customQueryParams = {};
      }
    
      if(this.oauthService.customQueryParams['login_hint'] !== user.unique_name){
        this.oauthService.customQueryParams['login_hint'] = user.unique_name;
        localStorage.setItem('login_hint', user.unique_name);
      }
    } 

    this.oauthService.events.subscribe((event:OAuthInfoEvent ) => {
      if(event.type === 'silently_refreshed'){
      }
    })
  }
 
  clickReportsTab() {
    this.router.navigateByUrl('/');
  }

  clickMesRapportsServeur() {
    this.router.navigateByUrl('/reports-dashboard');
  }
 

  redirectCartDetails() {
    this.router.navigateByUrl('/cart-details');
  }

}
