import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { routeAuthConfig } from './authentication.config';


@Injectable({providedIn: 'root'})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
    userLogged = false;

  constructor( private router: Router, private oauthService: OAuthService) {
  }

  public isUserLogged(): boolean { 
    this.userLogged = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken(); 
    return this.userLogged;
}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    // if the token is not valid, redirect to login page with first destination url as query
    if (!this.isUserLogged()) { 
      this.router.navigate([routeAuthConfig.loginPath]);
      return false;
    } else { // else return true, and let the user go to his page
      return true;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
