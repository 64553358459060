import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MasterComponent } from './master.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MasterRoutingModule } from './master-routing.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './shared/app.material';
import { HeaderlessLayoutModule } from './shared/headers/headerless-layout/headerless-layout.module';
import { HeaderLayoutModule } from './shared/headers/header-layout/header-layout.module';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@NgModule({
  declarations: [
    MasterComponent
  ],
  imports: [
    HeaderlessLayoutModule,
    HeaderLayoutModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    MasterRoutingModule,
    OAuthModule.forRoot()
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },   
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }

  ],
  bootstrap: [MasterComponent]
})
export class MasterModule {}


export function storageFactory() : OAuthStorage {
  return localStorage
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}