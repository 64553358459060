import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headerless-layout',
  templateUrl: './headerless-layout.component.html'
})
export class HeaderlessLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
