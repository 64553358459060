import { Component } from '@angular/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authenticationConfig } from './authentication/authentication.config';

@Component({
  selector: 'app-root',
  templateUrl: './master.component.html',
  template: '<router-outlet></router-outlet>',
})
export class MasterComponent {
  constructor(private oauthService: OAuthService) {
    oauthService.configure(authenticationConfig);
    oauthService.tokenValidationHandler = new NullValidationHandler();
    oauthService.setupAutomaticSilentRefresh();
  }

}
