export const environment = {
    production: false,
    authConfig: {       
        tenant_id: '6e603289-5e46-4e26-ac7c-03a85420a9a5',
        client_id: '82363e74-dec9-46c0-9ec2-5eb01cd3af7e',
        scope: 'api://82363e74-dec9-46c0-9ec2-5eb01cd3af7e/access',
        enableOAuth2Tracing: true
    },
    apiUrlPALMA: "https://api-pp.corp.thales/dms/palma/int/v3",
    apiKeyPALMA: "wnxF63KVn6wPbPzbchRAkWYU3jMllGjP",

    apiUrlSSR: "https://api-pp.corp.thales/dms/ssrs/int/v2/api1?/PALMA-DMS-Reports/INTEG",
    apiUrlBack: "https://api-pp.corp.thales/dms/advance-report/int/v2/reports",
    apiKeySSR: "wnxF63KVn6wPbPzbchRAkWYU3jMllGjP",
    env: "dev"
} 
