import { NgModule } from "@angular/core";
import { AppMaterialModule } from "src/app/shared/app.material";  
import { HeaderlessLayoutComponent } from "./headerless-layout.component";
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [HeaderlessLayoutComponent],
    imports: [
        AppMaterialModule, 
        RouterModule
    ],
    exports:[HeaderlessLayoutComponent]
})
export class HeaderlessLayoutModule { }