import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const routeAuthConfig = {
    basePath: '/auth',
    loginPath: '/login',
    callbackPath: '/callback',
    logoutPath: '/logout'
};


export const authenticationConfig: AuthConfig = {  
    issuer: `https://login.microsoftonline.com/${environment.authConfig.tenant_id}/v2.0`,
    loginUrl: `https://login.microsoftonline.com/${environment.authConfig.tenant_id}/oauth2/v2.0/authorize`,
    logoutUrl: `https://login.microsoftonline.com/${environment.authConfig.tenant_id}/oauth2/v2.0/logout`,
    redirectUri: `${window.location.origin}/callback`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
    clientId: environment.authConfig.client_id,
    scope: environment.authConfig.scope,
    showDebugInformation: environment.authConfig.enableOAuth2Tracing,
    clearHashAfterLogin: false,
    silentRefreshTimeout: 5000,
};
